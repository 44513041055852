<template>
  <div>

    <!-- #region::Searcher and button -->
    <b-form-row align-h="between">

      <!-- #region::Searcher -->
      <b-col md="4">
        <BasicSearcher
          :callback="handleSearch"
          placeholder="Buscar categorías"
        />
      </b-col>
      <!-- #endregion::Searcher -->

      <!-- #region::Cancel button -->
      <b-col
        v-if="isProcategoriesListModified"
        cols="12"
        md="auto"
        class="ml-auto"
      >
        <b-button
          variant="delete-btn"
          class="my-lg-0 w-100 delete-btn"
          @click="onCancelUpdateProcategoriesList"
        >
          Cancelar
        </b-button>
      </b-col>
      <!-- #endregion::Cancel button -->

      <!-- #region::Button for create new models -->
      <b-col
        cols="12"
        md="auto"
        :class="isProcategoriesListModified ? '' : 'ml-auto'"
      >
        <b-button
          variant="secondary"
          class="my-1 my-md-0 w-100"
          @click="isProcategoriesListModified ? onUpdateProcategoriesList() : showCreateProcategoryModal()"
        >
          <feather-icon
            v-if="!isProcategoriesListModified"
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">{{ isProcategoriesListModified ? 'Guardar' : 'Nueva categoría' }}</span>
        </b-button>
      </b-col>
      <!-- #endregion::Button for create new models -->

    </b-form-row>
    <!-- #endregion::Searcher and button -->

    <!-- #region::Skeleton -->
    <template v-if="isLoadingProcategories">
      <b-skeleton
        class="mt-1"
        animation="wave"
        width="100%"
        height="430px"
      />
    </template>
    <!-- #endregion::Skeleton -->

    <template v-else>
      <b-card
        class="mt-1 border border-dark shadow-none"
        no-body
      >
        <b-card-text>

          <!-- #region::Table -->
          <draggable
            v-if="availableProcategories"
            v-model="procategories"
            tag="ul"
            class="list-group"
            handle=".handle"
          >
            <div
              v-for="procategory in procategories"
              :key="procategory.Name"
              class="list-group-item"
            >
              <b-row>
                <b-col class="d-flex flex-row justify-content-start align-items-center">
                  <i class="fa-solid fa-grip-vertical handle" />
                  <span class="ml-75">{{ procategory.Name }} </span>
                  <div
                    class="pill-alert ml-50"
                    :class="procategory.Total > 0 ? 'alert-sapphire' : 'alert-gray'"
                    role="alert"
                  >
                    {{ procategory.Total }} {{ procategory.Total | pluralize('subcategoría') }}
                  </div>
                </b-col>
                <b-col md="auto">
                  <!-- #region::Edit procategory button -->
                  <b-button
                    v-b-tooltip.hover.top="'Editar'"
                    variant="flat-secondary"
                    class="btn-icon rounded-circle"
                    @click="$refs.saveModal.showModal('Editar categoría', procategory.IdTrueCategory, procategory.Name, 'Guardar')"
                  >
                    <feather-icon icon="Edit3Icon" />
                  </b-button>
                  <!-- #endregion::Edit procategory button -->

                  <!-- #region::Delete procategory button -->
                  <b-button
                    v-b-tooltip.hover.top="'Eliminar'"
                    variant="flat-secondary"
                    class="btn-icon rounded-circle"
                    @click="onDeleteProcategory(procategory)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                  <!-- #endregion::Delete procategory button -->
                </b-col>
              </b-row>

            </div>
          </draggable>
          <!-- #endregion::Table -->

          <!-- #region::Alert when no catgories are available -->
          <b-alert
            v-if="!availableProcategories && !isLoadingProcategories"
            variant="warning"
            class="my-0"
            show
          >
            <div class="alert-body">
              <span>No se encontraron categorías.</span>
            </div>
          </b-alert>
          <!-- #endregion::Alert when no catgories are available -->

        </b-card-text>
      </b-card>
    </template>

    <!-- #region::Modal for creating and editing model -->
    <SaveModal
      ref="saveModal"
      :max-length="25"
      :label-name="'Categoria'"
      placeholder="Escribe el nombre de la categoría"
      @model-saved="handleModelSaved"
    />
    <!-- #endregion::Modal for creating and editing model -->

  </div>
</template>

<script>
// #region Imports
import { mapState, mapGetters, mapActions } from 'vuex'
import {
  BRow, BCol, BButton, BSkeleton, BCard, BCardText, VBTooltip, BAlert, BFormRow,
} from 'bootstrap-vue'

import draggable from 'vuedraggable'
import SaveModal from '@/components/modals/SaveModal.vue'

import BasicSearcher from '@/components/forms/BasicSearcher.vue'

import getError from '@/helpers/ErrorsHandler'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// #endregion

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAlert,
    BButton,
    BFormRow,
    SaveModal,
    BCardText,
    BSkeleton,
    draggable,
    BasicSearcher,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      enabled: true,
      dragging: false,
    }
  },
  computed: {
    ...mapState('procategories', [
      'isLoadingProcategories', 'stateResponse', 'totalProcategories', 'searchTerm',
    ]),
    ...mapGetters('procategories', ['getProcategories', 'getBackupProcategories']),
    procategories: {
      get() { return this.getProcategories },
      set(value) { this.updateProcategories(value) },
    },
    backupProcategories: {
      get() { return this.getBackupProcategories },
    },
    availableProcategories() {
      return this.totalProcategories > 0
    },
    isProcategoriesListModified() {
      return JSON.stringify(this.procategories) !== JSON.stringify(this.backupProcategories)
    },
  },

  /**
  * Hook que se ejecuta cuando se crea el componente
  * @summary Establece el término de búsqueda en vacío y carga las procategorías
  */
  created() {
    this.setSearchTerm('')
    this.loadProcategories()
  },
  methods: {
    ...mapActions({
      setSearchTerm: 'procategories/setSearchTerm',
      sortCategories: 'procategories/sortCategories',
      loadProcategories: 'procategories/loadProcategories',
      createProcategory: 'procategories/createProcategory',
      updateProcategory: 'procategories/updateProcategory',
      deleteProcategory: 'procategories/deleteProcategory',
      updateProcategories: 'procategories/updateProcategories',
    }),
    // 07/09/2022 - TAG: Show de modal to create new category
    showCreateProcategoryModal() {
      this.$refs.saveModal.showModal('Nueva categoría')
    },
    // 01/09/2022 - TAG: Handle for the searcher component
    handleSearch(searchTerm) {
      this.setSearchTerm(searchTerm)
      this.loadProcategories()
    },
    checkMove(e) {
      window.console.log(`Future index: ${e.draggedContext.futureIndex}`)
    },

    /**
    * Gestión de categorías
    * @summary Crea una nueva categoría o actualiza una existente
    * @param {String} categoryName - Nombre de la categoría
    * @param {Number} categoryId - Id de la categoría
    * @exception {Error} error - Error de validación
    */
    async handleModelSaved(categoryName, categoryId = null) {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        if (categoryId) {
          await this.updateProcategory({
            id: categoryId,
            name: categoryName,
          })
        } else {
          await this.createProcategory({
            name: categoryName,
          })
        }

        this.$refs.saveModal.hideModal()
        this.showSweetAlert('¡Muy bien!', this.stateResponse.message, 'success')
      } catch (error) {
        this.$swal.close()
        this.showToast('Error de validación', getError(error), 'danger')
      }
    },

    /**
    * ELiminación de categoría
    * @summary Elimina una categoría si no tiene subcategorías relacionadas
    * @param {Object} category - Categoría a eliminar
    * @exception {Error} error - Error de validación
    */
    async onDeleteProcategory(category) {
      if (category.Total > 0) {
        this.$swal({
          title: 'Eliminar categoría',
          text: 'Esta categoría no se puede eliminar debido a que tiene subcategorías vinculadas. ¡Asígnalas a otra categoría para poder eliminarla!',
          icon: 'warning',
          confirmButtonText: 'Aceptar',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }

      const result = await this.$swal({
        title: 'Eliminar categoría',
        text: `¿Desea eliminar la categoría ${category.Name}? Esta acción no se podrá deshacer`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        try {
          this.$swal({
            title: 'Cargando...',
            allowOutsideClick: false,
          })
          this.$swal.showLoading()

          await this.deleteProcategory(category.IdTrueCategory)

          this.$refs.saveModal.hideModal()
          this.showSweetAlert('¡Muy bien!', 'La categoría fue eliminada correctamente', 'success')
        } catch (error) {
          this.$swal.close()
          this.showToast('Error de validación', getError(error), 'danger')
        }
      }
    },

    /**
    * Actualización de ordenamiento de categorías
    * @summary Actualiza el ordenamiento de las categorías en base al componente de la
    * lista que permite arrastrar y soltar
    * @exception {Error} error - Error de validación
    */
    async onUpdateProcategoriesList() {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        const formData = new FormData()
        this.procategories.forEach(({ IdTrueCategory }) => formData.append('IdTrueCategory[]', IdTrueCategory))

        await this.sortCategories(formData)

        this.showSweetAlert('¡Muy bien!', 'La lista de categorías fue actualizada correctamente', 'success')
      } catch (error) {
        this.$swal.close()
        this.showToast('Error de validación', getError(error), 'danger')
      }
    },

    /**
     * Cancelar actualización de categorías
     * @summary Cancela la actualización de categorías y restaura la lista de categorías
     * con la lista de respaldo
     */
    onCancelUpdateProcategoriesList() {
      this.procategories = this.backupProcategories
    },

    /**
     * Mensaje de confirmación
     * @summary Muestra un mensaje de confirmación y si se acepta, se recarga la página
     * @param {String} title - Título del mensaje de confirmación
     * @param {String} text - Contenido del mensaje de confirmación
     * @param {String} icon - Icono del mensaje de confirmación
     */
    showSweetAlert(title, text, icon) {
      this.$swal({
        title,
        text,
        icon,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    /**
    * Mensaje de notificación
    * @summary Despliega un mensaje de notificación
    * @param {string} title - Título del mensaje de notificación
    * @param {string} text - Contenido del mensaje de notificación
    * @param {string} variant - Tipo de notificación
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .handle {
    cursor: grab;
  }
</style>
