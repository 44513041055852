<template>
  <b-tabs>
    <b-tab
      title="Marcas"
      active
      lazy
    >
      <BrandsList />
    </b-tab>
    <b-tab
      title="Categorías"
      lazy
    >
      <ProcategoriesList />
    </b-tab>
    <b-tab
      title="Subcategorías"
      lazy
    >
      <CategoriesList />
    </b-tab>
    <b-tab
      title="Proveedores"
      lazy
    >
      <SuppliersList />
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'

import BrandsList from '@/modules/production/catalog/components/brands/BrandsList.vue'
import SuppliersList from '@/modules/production/catalog/components/suppliers/SuppliersList.vue'
import CategoriesList from '@/modules/production/catalog/components/categories/CategoriesList.vue'
import ProcategoriesList from '@/modules/production/catalog/components/procategories/ProcategoriesList.vue'

export default {
  components: {
    BTabs,
    BTab,
    BrandsList,
    SuppliersList,
    CategoriesList,
    ProcategoriesList,
  },
}
</script>
