<template>
  <div>
    <!-- begin::Modal for create or edit model -->
    <b-modal
      id="save-modal"
      ref="save-modal"
      centered
      :title="title"
      :no-close-on-backdrop="true"
      @show="resetModal"
      @hidden="resetModal"
    >

      <!-- #region::Form -->
      <validation-observer ref="modelForm">
        <b-form @submit="$event.preventDefault()">

          <slot name="model-selector" />

          <TextInputWithValidation
            v-model="name"
            vid="name"
            type="text"
            :label="labelName"
            :label-class="['red-dot']"
            :name="labelName.toLowerCase()"
            :maxlength="maxLength"
            :placeholder="$attrs.placeholder"
            :rules="{
              required: true,
            }"
          />

          <!-- is_not: originName, -->
        </b-form>
      </validation-observer>
      <!-- #endregion::Form -->

      <!-- #region::Footer -->
      <template #modal-footer>
        <b-button
          variant="delete-btn"
          class="delete-btn"
          @click="hideModal()"
        >
          Cancelar
        </b-button>
        <b-button
          variant="principal-btn"
          class="principal-btn"
          @click="handleSaveModel"
        >
          {{ saveButtonText || 'Aceptar' }}
        </b-button>
      </template>
      <!-- #endregion::Footer -->

    </b-modal>
    <!-- end::Modal for create or edit model -->

    <!-- begin::Overlay showing  -->
    <b-overlay
      :show="showOverlay"
      no-wrap
    />
  </div>
</template>

<script>
import { ValidationObserver, localize, extend } from 'vee-validate'
import { required, isNot } from '@validations'
import {
  BModal, VBModal, BOverlay, BButton, BForm,
} from 'bootstrap-vue'

import TextInputWithValidation from '@/components/forms/TextInputWithValidation.vue'

export default {
  components: {
    BForm,
    BModal,
    BButton,
    BOverlay,
    ValidationObserver,
    TextInputWithValidation,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    /**
     * Etiqueta del campo de texto
     */
    labelName: {
      type: String,
      required: true,
    },
    /**
     * Longitud máxima del campo de texto
     */
    maxLength: {
      type: Number,
      default: 200,
    },
  },
  data: () => ({
    id: null,
    name: '',
    title: '',
    originName: '',
    saveButtonText: '',
    showOverlay: false,

    /**
     * Validations rules
     */
    required,
    isNot,
  }),
  mounted() {
    localize('es')
    extend('is_not', {
      ...isNot,
      message: 'El campo {_field_} debe ser diferente',
    })
  },
  methods: {
    resetModal() {
      this.id = null
      this.name = ''
      this.originName = ''
      this.$emit('reset-data')
    },
    async handleSaveModel() {
      const success = await this.$refs.modelForm.validate()

      if (success) {
        if (this.id) {
          this.$emit('model-saved', this.name, this.id)
        } else {
          this.$emit('model-saved', this.name)
        }
      }
    },

    /**
    * Oculta el modal del modelo
    * @summary Oculta el modal del modelo, normalmente es llamado desde el componente padre
    * dentro de las listas del catálogo
    */
    hideModal() {
      this.$refs['save-modal'].hide()
    },

    /**
    * Muestra el modal para crear o editar un modelo
    * @summary Una vez que se abre el modal, se establece el título del modal,
    * el texto del botón de guardar y si se trata de una edición, se establece el id y
    * el nombre del modelo
    */
    showModal(title, modelId = null, modelName = null, saveButtonText = null) {
      this.$refs['save-modal'].show()
      this.title = title
      this.saveButtonText = saveButtonText ?? ''

      if (modelId && modelName) {
        this.id = modelId
        this.name = modelName
        this.originName = modelName
      }
    },
  },
}
</script>
