<template>
  <div>

    <!-- #region::Searcher and button -->
    <b-form-row align-h="between">

      <!-- #region::Searcher -->
      <b-col md="4">
        <BasicSearcher
          :callback="handleSearch"
          placeholder="Buscar subcategorías"
        />
      </b-col>
      <!-- #endregion::Searcher -->

      <!-- #region::Searcher -->
      <b-col md="2">
        <v-select
          v-model="filteredProcategory"
          label="Name"
          class="style-chooser"
          placeholder="Categoría"
          :options="getProcategories"
        />
      </b-col>
      <!-- #endregion::Searcher -->

      <!-- #region::Button for create new models -->
      <b-col
        cols="12"
        md="auto"
        class="ml-auto"
      >
        <b-button
          variant="secondary"
          class="my-1 my-md-0 w-100"
          @click="$refs.saveModal.showModal('Nueva subcategoría')"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Nueva subcategoría</span>
        </b-button>
      </b-col>
      <!-- #endregion::Button for create new models -->

    </b-form-row>
    <!-- #endregion::Searcher and button -->

    <!-- #region::Skeleton -->
    <template v-if="isLoadingCategories">
      <b-skeleton
        class="mt-1"
        animation="wave"
        width="100%"
        height="430px"
      />
    </template>
    <!-- #endregion::Skeleton -->

    <template v-else>
      <b-card
        class="mt-1 border border-dark shadow-none"
        no-body
      >
        <b-card-text>

          <!-- #region::Table -->
          <b-table
            v-if="availableCategories"
            :items="getCategories"
            :fields="fields"
            responsive
            class="my-0"
          >

            <!-- A virtual composite column -->
            <template #cell(CategoryName)="data">
              {{ data.item.CategoryName || 'Sin categoría' }}
            </template>

            <!-- #region::A virtual column for actions -->
            <template #cell(actions)="data">
              <b-button
                v-b-tooltip.hover.top="'Editar'"
                variant="flat-secondary"
                class="btn-icon rounded-circle"
                @click="onUpdateCategory(data.item)"
              >
                <feather-icon icon="Edit3Icon" />
              </b-button>
            </template>
            <!-- #region::A virtual column for actions -->

          </b-table>
          <!-- #endregion::Table -->

          <!-- #region::Alert when no catgories are available -->
          <b-alert
            v-if="!availableCategories && !isLoadingCategories"
            variant="warning"
            class="my-0"
            show
          >
            <div class="alert-body">
              <span>No se encontraron subcategorías.</span>
            </div>
          </b-alert>
          <!-- #endregion::Alert when no catgories are available -->

        </b-card-text>
      </b-card>
    </template>

    <!-- #region::Pagination & items per list -->
    <BasicPaginator
      v-if="availableCategories"
      ref="paginator"
      :callback="handleChangePagination"
      :total-rows="totalCategories"
    />
    <!-- #endregion::Pagination & items per list -->

    <!-- #region::Modal for creating and editing model -->
    <SaveModal
      ref="saveModal"
      :label-name="'Subcategoria'"
      placeholder="Escribe el nombre de la subcategoría"
      @model-saved="handleModelSaved"
      @reset-data="selectedProcategory = null"
    >
      <template v-slot:model-selector>
        <SelectWithValidation
          v-model="selectedProcategory"
          vid="procategory"
          rules="required"
          name="categoría"
          label="Categoría"
          property="Name"
          placeholder="Selecciona su categoría"
          :options="getProcategories"
          :label-class="['red-dot']"
        />
      </template>
    </SaveModal>
    <!-- #endregion::Modal for creating and editing model -->

  </div>
</template>

<script>
// #region Imports
import { mapActions, mapState, mapGetters } from 'vuex'
import {
  BFormRow, BCol, BButton, BTable, VBTooltip, BAlert, BSkeleton, BCard, BCardText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import SaveModal from '@/components/modals/SaveModal.vue'
import BasicSearcher from '@/components/forms/BasicSearcher.vue'
import BasicPaginator from '@/components/tables/BasicPaginator.vue'
import SelectWithValidation from '@/components/forms/SelectWithValidation.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import getError from '@/helpers/ErrorsHandler'
// #endregion

export default {
  components: {
    BFormRow,
    BCol,
    BCard,
    BTable,
    BAlert,
    BButton,
    vSelect,
    SaveModal,
    BSkeleton,
    BCardText,
    BasicSearcher,
    BasicPaginator,
    SelectWithValidation,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      /**
       * Selectedd procategory from saving modal
       */
      selectedProcategory: null,
      filteredProcategory: null,
      fields: [
        {
          key: 'Name',
          label: 'Nombre',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'CategoryName',
          label: 'Categoria',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'actions',
          label: 'Acciones',
          thClass: 'text-right bg-light',
          tdClass: 'text-right',
          thStyle: 'text-transform: none;',
        },
      ],
    }
  },
  computed: {
    ...mapState('categories', [
      'isLoadingCategories', 'stateResponse', 'totalCategories', 'searchTerm', 'currentPage', 'perPage',
    ]),
    ...mapGetters({
      getCategories: 'categories/getCategories',
      getProcategories: 'procategories/getProcategories',
    }),
    availableCategories() {
      return this.totalCategories > 0
    },
  },

  watch: {
    async filteredProcategory(filteredProcategory) {
      this.setCurrentPage(1)

      if (!this.availableCategories) {
        this.setPerPage(10)
      }

      if (filteredProcategory) {
        this.setFilteredProcategory(filteredProcategory)
      } else {
        this.setPerPage(10)
        // eslint-disable-next-line no-unused-expressions
        this.$refs.paginator?.resetPerPage()
        this.setFilteredProcategory(null)
      }
    },
  },

  /**
  * Hook que se ejecuta cuando se crea el componente
  * @summary Establece el término de búsqueda en vacío y carga las categorías
  */
  async created() {
    this.setSearchTerm('')
    await this.loadCategories()
    await this.loadProcategories()
  },

  /**
  * Hook que se ejecuta cuando se destruye el componente
  * @summary Establece la página actual en 1 y la cantidad de registros por página en 10
  */
  beforeDestroy() {
    this.setCurrentPage(1)
    this.setPerPage(10)
    this.setFilteredProcategory(null)
  },
  methods: {
    ...mapActions({
      setPerPage: 'categories/setPerPage',
      setSearchTerm: 'categories/setSearchTerm',
      loadCategories: 'categories/loadCategories',
      createCategory: 'categories/createCategory',
      updateCategory: 'categories/updateCategory',
      setCurrentPage: 'categories/setCurrentPage',
      setFilteredProcategory: 'categories/setFilteredProcategory',
      /**
       * Se utiliza para cargar el select de procategorías
       */
      loadProcategories: 'procategories/loadProcategories',
    }),

    /**
    * Evento de paginación
    * @summary Evento del componente de paginación. Establece la página actual y la cantidad
    * de registros por página
    * @param {number} currentPage - Página actual
    * @param {number} perPage - Elementos a mostrar por página
    */
    handleChangePagination(currentPage, perPage) {
      this.setCurrentPage(currentPage)
      this.setPerPage(perPage)
      this.loadCategories()
    },

    /**
    * Evento del buscador básico
    * @summary Establece la página actual en 1 y el término de búsqueda. Carga las categorías
    * @param {String} searchTerm - Término de búsqueda
    */
    handleSearch(searchTerm) {
      this.setCurrentPage(1)
      this.setSearchTerm(searchTerm)
      this.loadCategories()
    },

    /**
    * Gestión de categorías
    * @summary Crea una nueva categoría o actualiza una existente
    * @param {String} categoryName - Nombre de la categoría
    * @param {Number} categoryId - Id de la categoría
    * @exception {Error} - Error de validación
    */
    async handleModelSaved(categoryName, categoryId = null) {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        if (categoryId) {
          await this.updateCategory({
            id: categoryId,
            name: categoryName,
            idCategory: this.selectedProcategory.IdTrueCategory,
          })
        } else {
          await this.createCategory({
            name: categoryName,
            idCategory: this.selectedProcategory.IdTrueCategory,
          })
        }

        this.$refs.saveModal.hideModal()
        this.showSweetAlert('¡Muy bien!', this.stateResponse.message, 'success')
      } catch (error) {
        this.$swal.close()
        this.showToast('Error de validación', getError(error), 'danger')
      }
    },

    /**
    * Actualización de categoría
    * @summary Muestra el modal en modo edición y establece la procategoría seleccionada
    * @param {Object} category - Categoría a editar
    */
    onUpdateCategory(category) {
      this.$refs.saveModal.showModal('Editar subcategoría', category.IdCategory, category.Name, 'Guardar')
      this.selectedProcategory = this.getProcategories.find(procategory => procategory.IdTrueCategory === category.FK_IdTrueCategory)
    },

    /**
    * Muestra un mensaje de tipo alert
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} icon - Icono mostrado en el mensaje
    */
    showSweetAlert(title, text, icon) {
      this.$swal({
        title,
        text,
        icon,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    /**
    * Mensaje de notificación
    * @summary Despliega un mensaje de notificación
    * @param {string} title - Título del mensaje de notificación
    * @param {string} text - Contenido del mensaje de notificación
    * @param {string} variant - Tipo de notificación
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  .style-chooser {
  background: #ffffff;
}
</style>
