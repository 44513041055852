<template>
  <div>

    <!-- #region::Searcher and button -->
    <b-row align-h="between">

      <!-- #region::Searcher -->
      <b-col md="4">
        <BasicSearcher
          :callback="handleSearch"
          placeholder="Buscar proveedores"
        />
      </b-col>
      <!-- #endregion::Searcher -->

      <!-- #region::Button for create new models -->
      <b-col
        cols="12"
        md="auto"
      >
        <b-button
          variant="secondary"
          class="my-1 my-md-0 w-100"
          @click="showCreateSupplierModal"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Nuevo proveedor</span>
        </b-button>
      </b-col>
      <!-- #endregion::Button for create new models -->

    </b-row>
    <!-- #endregion::Searcher and button -->

    <!-- #region::Skeleton -->
    <template v-if="isLoadingSuppliers">
      <b-skeleton
        class="mt-1"
        animation="wave"
        width="100%"
        height="430px"
      />
    </template>
    <!-- #endregion::Skeleton -->

    <template v-else>
      <b-card
        class="mt-1 border border-dark shadow-none"
        no-body
      >
        <b-card-text>

          <!-- #region::Table -->
          <b-table
            v-if="availableSuppliers"
            :items="getSuppliers"
            :fields="fields"
            responsive
            class="my-0"
          >

            <!-- #region::A virtual column for actions -->
            <template #cell(actions)="data">
              <b-button
                v-b-tooltip.hover.top="'Editar'"
                variant="flat-secondary"
                class="btn-icon rounded-circle"
                @click="showEditSupplierModal(data)"
              >
                <feather-icon icon="Edit3Icon" />
              </b-button>
            </template>
            <!-- #region::A virtual column for actions -->

          </b-table>
          <!-- #endregion::Table -->

          <!-- #region::Alert when no suppliers are available -->
          <b-alert
            v-if="!availableSuppliers && !isLoadingSuppliers"
            variant="warning"
            class="my-0"
            show
          >
            <div class="alert-body">
              <span>No se encontraron proveedores.</span>
            </div>
          </b-alert>
          <!-- #endregion::Alert when no suppliers are available -->

        </b-card-text>
      </b-card>
    </template>

    <!-- #region::Pagination & items per list -->
    <BasicPaginator
      v-if="availableSuppliers"
      :callback="handleChangePagination"
      :total-rows="totalSuppliers"
    />
    <!-- #endregion::Pagination & items per list -->

    <!-- #region::Modal for creating and editing model -->
    <SaveModal
      ref="saveModal"
      :label-name="'Nombre'"
      placeholder="Escribe el nombre del proveedor"
      @model-saved="handleModelSaved"
    />
    <!-- #endregion::Modal for creating and editing model -->

  </div>
</template>

<script>
// #region Imports
import { mapActions, mapState, mapGetters } from 'vuex'
import {
  BRow, BCol, BButton, BTable, VBTooltip, BAlert, BSkeleton, BCard, BCardText,
} from 'bootstrap-vue'

import SaveModal from '@/components/modals/SaveModal.vue'
import BasicSearcher from '@/components/forms/BasicSearcher.vue'
import BasicPaginator from '@/components/tables/BasicPaginator.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import getError from '@/helpers/ErrorsHandler'
// #endregion

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAlert,
    BButton,
    SaveModal,
    BSkeleton,
    BCardText,
    BasicSearcher,
    BasicPaginator,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      fields: [
        {
          key: 'Name',
          label: 'Nombre',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'actions',
          label: 'Acciones',
          thClass: 'text-right bg-light',
          tdClass: 'text-right',
          thStyle: 'text-transform: none;',
        },
      ],
    }
  },
  computed: {
    ...mapState('suppliers', [
      'isLoadingSuppliers', 'stateResponse', 'totalSuppliers', 'searchTerm', 'currentPage', 'perPage',
    ]),
    ...mapGetters('suppliers', ['getSuppliers']),

    // 14/09/2022 - TAG: True if there are available suppliers
    availableSuppliers() {
      return this.totalSuppliers > 0
    },
  },
  created() {
    // 07/09/2022 - TAG: Load all suppliers with default params
    this.setSearchTerm('')
    this.loadSuppliers()
  },
  beforeDestroy() {
    this.setCurrentPage(1)
    this.setPerPage(10)
  },
  methods: {
    ...mapActions('suppliers', [
      'loadSuppliers', 'createSupplier', 'updateSupplier', 'setSearchTerm', 'setCurrentPage', 'setPerPage',
    ]),
    // 07/09/2022 - TAG: Show de modal to create new category
    showCreateSupplierModal() {
      this.$refs.saveModal.showModal('Nuevo proveedor')
    },
    // 07/09/2022 - TAG: Show de modal to edit a existing category
    showEditSupplierModal({ item }) {
      this.$refs.saveModal.showModal('Editar proveedor', item.IdSupplier, item.Name, 'Guardar')
    },
    // 01/09/2022 - TAG: Handle for the paginator component
    handleChangePagination(currentPage, perPage) {
      this.setCurrentPage(currentPage)
      this.setPerPage(perPage)
      this.loadSuppliers()
    },
    // 01/09/2022 - TAG: Handle for the searcher component
    handleSearch(searchTerm) {
      this.setCurrentPage(1)
      this.setSearchTerm(searchTerm)
      this.loadSuppliers()
    },
    // 14/09/2022 - TAG: Handle save event from modal to create or update suppliers
    async handleModelSaved(supplierName, supplierId = null) {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()

        if (supplierId) {
          await this.updateSupplier({
            id: supplierId,
            name: supplierName,
          })
        } else {
          await this.createSupplier({
            name: supplierName,
          })
        }

        this.$refs.saveModal.hideModal()
        this.showSweetAlert('¡Muy bien!', this.stateResponse.message, 'success')
      } catch (error) {
        this.$swal.close()
        this.showToast('Error de validación', getError(error), 'danger')
      }
    },
    // 13/09/2022 - TAG: Show sweet alert message
    showSweetAlert(title, text, icon) {
      this.$swal({
        title,
        text,
        icon,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
